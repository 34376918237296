import React from "react"
import { Link, graphql } from "gatsby"
import SEOHeader from "../components/seo-header"
import Layout from "../components/layout"
import HeaderBanner from "../components/headerbanner"
import "../styles/style.scss"
import "../styles/blog.scss"
import Img from "gatsby-image"
import BlogSearchComponent from "../components/search/blogsearch"
class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges
    const category = Object.values(data.category.distinct)
    const { currentPage, blognumPages } = this.props.pageContext
    const isFirst = currentPage === 1
    const isLast = currentPage === blognumPages
    const prevPage = currentPage - 1 === 1 ? "/" : (currentPage - 1).toString()
    const nextPage = (currentPage + 1).toString()

    const Category = category.map(cate => {
      return (
        <li>
          <Link
            to={
              "/blog/category/" +
              cate
                .replace(/&/g, "")
                .toLowerCase()
                .replace("  ", "-")
                .replace(" ", "-")
            }
          >
            {cate}
          </Link>
        </li>
      )
    })
    return (
      <div>
        <SEOHeader
          title="Gofloaters Blog"
          description="Gofloaters Blog - GoFloaters is a platform that helps you find workspaces that can be booked instantly."
          socialURL="https://assets.gofloaters.com/socialimage/homepage.jpg"
          pinterest="true"
        ></SEOHeader>
        <Layout location={this.props.location} title={siteTitle}>
          <HeaderBanner>
            <h1>Blog</h1>{" "}
            <div className="container">
              <div className="row">
                <div className="col-md-3"></div>
                <div className="col-md-6">
                  <BlogSearchComponent />
                </div>
              </div>
            </div>
          </HeaderBanner>
          <div className="container">
            <div className="row">
              <div className="col-md-6"></div>
              <div className="col-md-12">
                <ul className="categoryType">
                  <li>
                    <Link to="/blog" className="active">
                      All
                    </Link>
                  </li>
                  {Category}
                </ul>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              {posts.map(({ node }) => {
                const title = node.frontmatter.title || node.fields.slug
                return (
                  <div className="col-md-6">
                    <br></br>
                    <div className="BlogCard" key={node.fields.slug}>
                      <Link to={"/blog" + node.fields.slug}>
                        <h4 className="BlogTitle" title={title}>
                          {title}
                        </h4>
                        <div>
                          <Img
                            fluid={
                              node.frontmatter.featuredImage.childImageSharp
                                .fluid
                            }
                            style={{ borderRadius: "16px" }}
                            className="img-responsive"
                            title={title}
                            alt={title}
                          />
                        </div>
                        <br></br>
                        <p className="blogDate">
                          By {node.frontmatter.author} {" / "}
                          {node.timeToRead} mins read
                        </p>
                        <p
                          className="BlogDesc"
                          dangerouslySetInnerHTML={{ __html: node.excerpt }}
                        />
                        <br></br>
                        <Link
                          className="claimnowButton"
                          to={"/blog" + node.fields.slug}
                        >
                          Read More
                        </Link>
                        <br></br>
                      </Link>
                      <br></br>
                    </div>
                  </div>
                )
              })}
              <div className="col-md-12">
                <br></br>
                <ul
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    alignItems: "center",
                    listStyle: "none",
                    padding: 0,
                  }}
                >
                  {!isFirst && (
                    <Link to={"/blog/" + prevPage} rel="prev">
                      ← Previous Page
                    </Link>
                  )}
                  {Array.from({ length: blognumPages }, (_, i) => (
                    <li
                      key={`pagination-number${i + 1}`}
                      style={{
                        margin: 0,
                      }}
                    >
                      <Link
                        to={`/blog/${i === 0 ? "" : i + 1}`}
                        style={{
                          padding: "10px 15px",
                          borderRadius: "50%",
                          textDecoration: "none",
                          color: i + 1 === currentPage ? "#ffffff" : "",
                          background: i + 1 === currentPage ? "#007acc" : "",
                        }}
                      >
                        {i + 1}
                      </Link>
                    </li>
                  ))}
                  {!isLast && (
                    <Link to={"/blog/" + nextPage} rel="next">
                      Next Page →
                    </Link>
                  )}
                </ul>
                <br></br>
              </div>
            </div>
          </div>
        </Layout>
      </div>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query blogPageQuery($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    category: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: { templateKey: { eq: "blog-post" } }
        fileAbsolutePath: { regex: "/(blog)/" }
      }
    ) {
      distinct(field: frontmatter___category)
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        fileAbsolutePath: { regex: "/(blog)/" }
        frontmatter: { templateKey: { eq: "blog-post" } }
      }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          timeToRead
          frontmatter {
            date 
            title
            author
            featuredImage {
              relativeDirectory
              publicURL
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
